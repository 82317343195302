import BaseRequest from './BaseRequest';

export enum Role {
    ADMIN = 1,
    CONTRIBUTOR = 2,
    USER = 3
}

export interface IUser {
    userId: number,
    email: string,
    createdAt: string,
    role: Role,
    customer: {
        customerId: number,
        name: string,
    }
}

export interface IRequestResetPasswordResult {
    isRequested: boolean,
}

export interface IValidateResetPasswordResult {
    isValid: boolean,
}

export interface IResetPasswordResult {
    isUpdated: boolean,
}

export class UserRequest extends BaseRequest {

    constructor() {
        super();
    }

    public async signIn(email: string, password: string, isRememberMeChecked: boolean): Promise<IUser> {
        const response = await this.axiosInstance.post('/v1/auth/sign-in', {email, password, isRememberMeChecked});
        return response.data
    }

    public async signOut(): Promise<void> {
        await this.axiosInstance.post('/v1/auth/sign-out', {});
    }

    public async retrieveLoggedUser(): Promise<IUser> {
        const response = await this.axiosInstance.get('/v1/users/me');
        return response.data;
    }

    public async requestResetPassword(email: string): Promise<IRequestResetPasswordResult> {
        const response = await this.axiosInstance.post('/v1/auth/request-reset-password', {email});
        return response.data;
    }

    public async validateRecoveryToken(recoveryToken: string): Promise<IValidateResetPasswordResult> {
        const response = await this.axiosInstance.get(`/v1/auth/reset-password/${recoveryToken}`);
        return response.data;
    }

    public async resetPassword(recoveryToken: string, password: string, repeatPassword: string): Promise<IResetPasswordResult> {
        const response = await this.axiosInstance.put(`/v1/auth/reset-password/${recoveryToken}`, {
            recoveryToken, password, repeatPassword
        });
        return response.data;
    }
}

export default new UserRequest();

import axios, {AxiosInstance} from 'axios';


const baseUrl = process.env.REACT_APP_API_BASE_URL;

export default class BaseRequest {

    protected axiosInstance: AxiosInstance;

    constructor() {
        this.axiosInstance = axios.create({
            baseURL: baseUrl + '/api-internal',
            withCredentials: true
        });

    }
}
